<template>
  <a-table
    :columns="columns"
    :data-source="tasksList"
    :pagination="pagination"
    :scroll="{ y: 480 }"
    @change="handleTableChange"
  >
    <template #bodyCell="{ column, text }">
      <template v-if="column.dataIndex === 'Task__created_at'">
        <span>
          {{ dateHelper.formatDate(text) }}
        </span>
      </template>
    </template>
    <template #title>
      <a-typography-title :level="4">Tasks</a-typography-title></template
    >
  </a-table>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
const columns = [
  {
    title: 'Name',
    dataIndex: 'Task__taskName',
  },
  {
    title: 'Created at',
    dataIndex: 'Task__created_at',
  },
  {
    title: 'Videos',
    dataIndex: 'numVideos',
  },
  {
    title: 'Labeled Videos',
    dataIndex: 'numLabelled',
  },
];

import dateHelper from '../../../shared/Helpers/dateHelper';

export default defineComponent({
  props: ['tasksList'],
  setup(props) {
    const currentPage = ref(1);

    function handleTableChange(pag) {
      const { current } = pag;
      currentPage.value = current;
    }

    const pagination = computed(() => ({
      total: props.tasksList.length,
      current: currentPage.value,
      showSizeChanger: true,
      position: ['bottomCenter'],
    }));
    return {
      columns,
      pagination,
      handleTableChange,
      dateHelper,
    };
  },
});
</script>
