<template>
  <div class="content-i">
    <div class="content-box">
      <Tasks :tasksList="taskList" />
    </div>
  </div>
</template>

<script>
import httpClient from '../../../../service/httpClient.js';
import Tasks from './Tasks.vue';

export default {
  name: 'device-component',
  components: { Tasks },
  data() {
    return {
      taskList: [],
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const organization = localStorage.getItem('organization');
      const taskList = await httpClient.getData(
        'organization/task/taskpage?organizations=' + organization
      );
      this.taskList = taskList;
    },
  },
};
</script>

<style>
table,
tr,
td {
  text-align: left;
  margin-right: auto;
  margin-left: 0px;
}
</style>
